import { graphql, useStaticQuery } from "gatsby";

export const useAllMonetizations = () => {
  const {
    allStrapiMonetization: { nodes },
  } = useStaticQuery(graphql`
    query useAllMonetizations {
      allStrapiMonetization {
        nodes {
          status
          slug
          region
          name
        }
      }
    }
  `);

  return nodes;
};
