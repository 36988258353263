import { Link } from "gatsby";
import React from "react";
import { generateBlogRoute } from "../../../lib/routes/generate-blog-route";
import { generateMonetizationRoute } from "../../../lib/routes/routes";
import { SectionHeading } from "../Typography/SectionHeading";
import { useAllMonetizations } from "./hooks/useAllMonetizations";

export interface MonetizationProps {
  className?: string;
}

export const Monetization = (props: MonetizationProps) => {
  const { className = "" } = props;
  const monetizations = useAllMonetizations();

  return (
    <div className={`className`}>
      <header>
        <SectionHeading>SPEŇAŽOVANIE</SectionHeading>
        <p className="mt-4 w-5/6">
          Zoznam speňažovaného majetku úpadcov. Pre viac informácií ohľadom
          konkrétneho speňažovaného majetku kliknite na tlačidlo "zobraziť
          viac", v stĺpci podrobnosti.
        </p>
      </header>
      <table className="w-full lg:w-3/4 mx-auto table-auto mt-12">
        <thead>
          <tr className="text-left">
            <th className="pb-4 text-primary">Úpadca</th>
            <th className="pb-4 text-primary hidden xl:table-cell">Kraj</th>
            <th className="pb-4 text-primary hidden xl:table-cell">
              Stav speňaženia
            </th>
            <th className="pb-4 text-primary">Podrobnosti</th>
          </tr>
        </thead>
        <tbody>
          {monetizations.map((monetization) => (
            <tr key={monetization.slug}>
              <td className="table-cell  text-primary py-4 pr-4 xl:px-0 xl:py-2">
                {monetization.name}
              </td>
              <td className="xl:table-cell text-primary hidden ">
                {monetization.region}
              </td>
              <td className="xl:table-cell  text-primary hidden">
                {monetization.status}
              </td>
              <td>
                <Link
                  to={generateMonetizationRoute(monetization.slug)}
                  className="hover:text-secondary transition duration-200 underline  text-primary"
                >
                  Zobraziť viac
                </Link>
              </td>
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );
};
