import React, { ReactNode } from "react";

export interface SectionHeadingProps {
  className?: string;
  children: ReactNode;
}

export const SectionHeading = (props: SectionHeadingProps) => {
  const { className = "", children } = props;
  return (
    <h2
      {...props}
      className={`${className} text-secondary text-3xl font-bold mb-4`}
    >
      {children}
    </h2>
  );
};
